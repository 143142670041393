<template><div><h1 id="developer-mode" tabindex="-1"><a class="header-anchor" href="#developer-mode"><span>Developer Mode</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Entering and exiting Developer mode will <strong>wipe all</strong> user data!
Backup anything if you have to.</p>
</div>
<h2 id="reset-gbb-flags" tabindex="-1"><a class="header-anchor" href="#reset-gbb-flags"><span>Reset GBB Flags</span></a></h2>
<p>If you get this error &quot;WARNING: TONORM prohibited by GBB_FORCE_DEV_SWITCH_ON&quot; or find the &quot;Return to secure mode&quot; button missing, you need to reset your gbb flags.</p>
<ol>
<li>
<p>Boot chromeOS.</p>
</li>
<li>
<p>Press <code v-pre>Control</code> + <code v-pre>Alt</code> + <code v-pre>T</code> to bring up a crosh terminal.</p>
</li>
<li>
<p>Run <a href="https://mrchromebox.tech/#fwscript" target="_blank" rel="noopener noreferrer">MrChromebox's firmware utility script.</a></p>
<ul>
<li>Type <code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code> and press Enter.</li>
<li>If you encounter certificate related errors when downloading the script from ChromeOS, then add <code v-pre>-k</code> to the <code v-pre>curl</code> and script command to bypass SSL certificate checking as so:
<ul>
<li><code v-pre>cd; curl -LOk mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code></li>
</ul>
</li>
</ul>
</li>
<li>
<p>Select option 3: &quot;Set Boot Options (GBB flags)&quot;.</p>
</li>
<li>
<p>Select option 5: &quot;Reset to factory default&quot;.</p>
</li>
<li>
<p>Your done.</p>
</li>
</ol>
<h3 id="entering-developer-mode" tabindex="-1"><a class="header-anchor" href="#entering-developer-mode"><span>Entering Developer Mode</span></a></h3>
<ol>
<li>Boot your system to the &quot;You are in Developer Mode&quot; or &quot;OS Verification is OFF&quot; screen.</li>
</ol>
<h4 id="chromebooks-chromeboxes" tabindex="-1"><a class="header-anchor" href="#chromebooks-chromeboxes"><span>Chromebooks/Chromeboxes</span></a></h4>
<ol>
<li>
<p>Press the <code v-pre>Spacebar</code>. If it brings up a warning asking for confirmation, Press <code v-pre>Enter</code>.</p>
</li>
<li>
<p>Press <code v-pre>Enter</code>. The screen should turn off breifly and say that its returning to &quot;Normal (Verified) Mode...&quot;.</p>
</li>
<li>
<p>Once its done, Setup the system like normal.</p>
</li>
</ol>
<h4 id="tablets" tabindex="-1"><a class="header-anchor" href="#tablets"><span>Tablets</span></a></h4>
<ol>
<li>
<p>Select &quot;Enable OS Verification&quot;.</p>
</li>
<li>
<p>Once its done, Setup the system like normal.</p>
</li>
</ol>
</div></template>


